import { BaseModel } from "./BaseModel";
import { App } from "../App";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'type',
    type: 'string'
}, {
    name: 'always',
    type: 'bool'
}, {
    name: 'attributes'
}, {
    name: 'notificators',
    type: 'string'
}, {
    name: 'calendarId',
    type: 'int'
}];

export class Notification extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }

    get eventString() {
        return App.App.getEventString(this.type);
    }
}