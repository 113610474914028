import React from "react";
import { FormGroup, FormLabel, Button } from "react-bootstrap";
import { t } from "../../../utils/Translator";
import { MDBDataTable } from "mdbreact";
import Datetime from "react-datetime";
import { StatisticsService, AppService } from "../../../services";
import '../../../assets/scss/new.scss';

class StatisticsListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      item: null,
      period: {
        from: new Date(new Date().getTime() - 30 * 60 * 1000),
        to: new Date()
      },
      data: {
        columns: [
          {
            label: t("statisticsCaptureTime"),
            field: "statisticsCaptureTime",
            sort: "disabled"
          },
          {
            label: t("statisticsActiveUsers"),
            field: "statisticsActiveUsers",
            sort: "disabled"
          },
          {
            label: t("statisticsActiveDevices"),
            field: "statisticsActiveDevices",
            sort: "disabled"
          },
          {
            label: t("statisticsRequests"),
            field: "statisticsRequests",
            sort: "disabled"
          },
          {
            label: t("statisticsMessagesReceived"),
            field: "statisticsMessagesReceived",
            sort: "disabled"
          },
          {
            label: t("statisticsMessagesStored"),
            field: "statisticsMessagesStored",
            sort: "disabled"
          },
          {
            label: t("notificatorMail"),
            field: "notificatorMail",
            sort: "disabled"
          },
          {
            label: t("notificatorSms"),
            field: "notificatorSms",
            sort: "disabled"
          },
          {
            label: t("statisticsGeocoder"),
            field: "statisticsGeocoder",
            sort: "disabled"
          },
          {
            label: t("statisticsGeolocation"),
            field: "statisticsGeolocation",
            sort: "disabled"
          }
        ],
        rows: [
        ]
      }
    };
    this.changePeriod = this.changePeriod.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.showStastics = this.showStastics.bind(this);
  }

  hideForm() {
    this.setState({
      showForm: false,
      item: null
    });
  }

  async showStastics() {
    const filter = {
      from: this.state.period.from.toISOString(),
      to: this.state.period.to.toISOString()
    };
    try {
      const data = await StatisticsService.getStatistics(filter);
      const row_data = [];
      if (data) {
        // eslint-disable-next-line array-callback-return
        Object.values(data).map(d => {
          var capture_time = "";
          if (d.captureTime) {
            capture_time = d.captureTime.split(/[T]/);
          }

          row_data.push({
            statisticsCaptureTime: capture_time[0],
            statisticsActiveUsers: d.activeUsers,
            statisticsActiveDevices: d.activeDevices,
            statisticsRequests: d.requests,
            statisticsMessagesReceived: d.messagesReceived,
            statisticsMessagesStored: d.messagesStored,
            notificatorMail: d.mailSent,
            notificatorSms: d.smsSent,
            statisticsGeocoder: d.geocoderRequests,
            statisticsGeolocation: d.geolocationRequests
          });
        });

        this.setState(prevState => ({
          data: {
            columns: prevState.data.columns,
            rows: row_data
          }
        }));
      }
    } catch (ex) {
      AppService.showError(ex);
    }
  }

  changePeriod(type, value) {
    this.setState({
      period: {
        ...this.state.period,
        [type]: value
      }
    });
  }

  render() {
    const { onHide } = this.props;
    return (
      <React.Fragment>
        <div className="container-wrapper grow">
          <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm overflow">
            <div className="flex-grow-1 pt-3 overflow">
              <div className="container">
                <div className="row">
                  <FormGroup className="ml-4 mr-4 col-md-3 nopadding">
                    <FormLabel>{t("reportFrom")}</FormLabel>
                    <Datetime
                      timeFormat={"HH:mm"}
                      dateFormat="YYYY-MM-DD"
                      defaultValue={this.state.period.from}
                      onChange={e => this.changePeriod("from", e)}
                    >
                      {" "}
                    </Datetime>
                  </FormGroup>
                  <FormGroup className="ml-4 mr-4 col-md-3 nopadding">
                    <FormLabel>{t("reportTo")}</FormLabel>
                    <Datetime
                      timeFormat={"HH:mm"}
                      dateFormat="YYYY-MM-DD"
                      defaultValue={this.state.period.to}
                      onChange={e => this.changePeriod("to", e)}
                    >
                      {" "}
                    </Datetime>
                  </FormGroup>
                </div>
                <section className="ml-2 mr-2 statisc-table">
                  {/* <MDBTable responsive>
                    <MDBTableHead columns={this.state.data.columns} />
                    <MDBTableBody rows={this.state.data.rows} />
                  </MDBTable> */}
                  <MDBDataTable
                    striped
                    searching={false}
                    bordered={false}
                    small
                    hover
                    data={this.state.data}
                  />
                </section>

                {/* <List items={this.props.items} onDelete={this.deleteItem} onSelect={this.showForm} enableDelete={true} enableSelection={false} /> */}
              </div>
            </div>
            <footer className="footer">
              <div className="container d-flex">
                <div className="flex-grow-1 d-flex"></div>
                <Button variant="secondary" onClick={this.showStastics}>
                  {t("reportShow")}
                </Button>
                <Button variant="secondary" onClick={onHide}>
                  <i className="mdi mdi-close"></i> {t("sharedCancel")}
                </Button>
              </div>
            </footer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// export default connect(mapStateToProps, null)();
export default StatisticsListPage;
