import { BaseModel } from "./BaseModel";
import Server from "./Server";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'login',
    type: 'string'
}, {
    name: 'email',
    type: 'string'
}, {
    name: 'password',
    type: 'string'
}, {
    name: 'phone',
    type: 'string'
}, {
    name: 'readonly',
    type: 'boolean'
}, {
    name: 'admin',
    type: 'boolean'
}, {
    name: 'map',
    type: 'string'
}, {
    name: 'latitude',
    type: 'float'
}, {
    name: 'longitude',
    type: 'float'
}, {
    name: 'zoom',
    type: 'int'
}, {
    name: 'twelveHourFormat',
    type: 'boolean'
}, {
    name: 'coordinateFormat',
    type: 'string'
}, {
    name: 'disabled',
    type: 'boolean'
}, {
    name: 'expirationTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'deviceLimit',
    type: 'int'
}, {
    name: 'userLimit',
    type: 'int'
}, {
    name: 'deviceReadonly',
    type: 'boolean'
}, {
    name: 'limitCommands',
    type: 'boolean'
}, {
    name: 'poiLayer',
    type: 'string'
}, {
    name: 'token',
    type: 'string'
}, {
    name: 'attributes'
},
{
    name: 'administrator',
}];

export class User extends BaseModel {

    constructor(data = {}) {
        super(fields, data);
    }

    /*get mapConfig() {
        const serverConfig = Server.mapConfig;

        let config = maps.services[this.map] || { url: serverConfig.url, subdomains: serverConfig.subdomains };
        if (this.map === 'custom') {
            config = { url: Server.mapUrl, subdomains: [] };
        }

        config.zoom = this.zoom || serverConfig.zoom;
        config.center = (this.latitude !== 0 && this.longitude !== 0) ? [this.latitude, this.longitude] : serverConfig.center;
        return config;
    }*/
}