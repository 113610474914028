import { store } from "../store";
import session from "../store/session";
import events from "../utils/Events";
import WebSocketService from "./WebSocketService";
import axios from "axios";
import SessionService from "./SessionService";
import ServerService from "./ServerService";
import app from "../store/app";
import localeStore from "../store/locale";
import DevicesService from "./DevicesService";
import GroupService from "./GroupsService";
import positions from "../store/positions";
import constants from "../config/constants";
import { Translator, t } from "../utils/Translator";
import languages from "../config/languages";
import GeofencesService from "./GeofencesService";
import { Server } from "../models";
import events_store from "../store/events";
import * as iziToast from "izitoast";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setAttr, deepClone } from "../utils/functions";
import * as _ from "lodash";
import PositionsService from "./PositionsService";
import MaintenancesService from "./MaintenancesService";
import DriversService from "./DriversService";
import CalendarsService from "./CalendarsService";
import NotificationTypesService from "./NotificationTypesService";
import ComputedAttributesService from "./ComputedAttributesService";
import CommandTypesService from "./CommandTypesService";
import CommandsService from "./CommandsService";
import NotificatorsService from "./NotificatorsService";
import NotificationsService from "./NotificationsService";
import speed_units from "../store/app/speed_units";
import user_attributes from "../store/users/attributes";
import maintenance_types from "../store/maintenances/types";
import alarm_types from "../store/app/alarm_types";
import device_attributes from "../store/devices/attributes";
import { isArray } from "util";
import devices from "../store/devices";

export class AppService {
  constructor() {
    this._messages = {};
    this._locale = "es";
    this._refreshTimer = null;
    this._server = null;
    this._user = null;
    this._sounds = {};
    this._showNotifications = false;
    this._connectionTimeout = null;
    this.loadingCount = 0;
  }

  get messages() {
    return this._messages;
  }

  get lang() {
    if (!Object.keys(languages).includes(this._locale)) {
      this._locale = "en";
    }
    return languages[this._locale];
  }

  set messages(value) {
    this._messages = value;
  }

  get locale() {
    return this._locale;
  }

  set locale(value) {
    if (!Object.keys(languages).includes(value)) {
      value = "en";
    }
    this.setLocalPreference("language", value);
    this._locale = value;
  }

  get server() {
    return this._server;
  }

  set server(value) {
    this._server = value;
  }

  get user() {
    return this._user;
  }

  set user(value) {
    this._user = value;
  }

  async prepare() {
    //merge user attributes
    store.dispatch(
      user_attributes.actions.merge(
        store.getState().common_user_attributes.common_user_attributes
      )
    );

    //merge device attributes
    store.dispatch(
      device_attributes.actions.merge(
        store.getState().common_device_attributes.common_device_attributes
      )
    );

    //merge position attributes
    const position_attrs = store.getState().position_attributes
      .position_attributes;
    // eslint-disable-next-line array-callback-return
    Object.values(position_attrs).map(attr => {
      if (attr.valueType === "number") {
        store.dispatch(maintenance_types.actions.added(attr));
      }
    });

    //load the configuration
    this.loadLocalConfig();

    //reset speed units
    store.dispatch(speed_units.actions.reset());

    _.merge(constants, this._currentLocalConfig);
    //set map options
    store.dispatch({
      type: app.types.MAP_OPTIONS_LOADED,
      payload: constants.mapTypes[constants.selectedMapType].mapOptions
    });

    //save map config controls
    events.on(app.types.MAP_OPTIONS_CHANGE, options => {
      const copy = {};
      Object.keys(options).map(k => {
        if (k !== "map_config") {
          copy[k] = { ...options[k], value: options[k] };
        }
      });
      this.setLocalPreference(
        "mapTypes." + constants.selectedMapType + ".mapOptions",
        copy
      );
    });

    //set notifications settings
    store.dispatch({
      type: app.types.SET_NOTIFICATIONS_SETTINGS,
      payload: constants.notifications
    });

    //save notifications settings
    events.on(app.types.SET_NOTIFICATIONS_SETTINGS, options => {
      this.setLocalPreference("notifications.enabled", options.enabled);
      this.setLocalPreference(
        "notifications.soundEnabled",
        options.soundEnabled
      );

      //remove toasts if not enabled
      if (!options.enabled) {
        this.clearToasts();
      }
    });

    //set display attributes options
    const showAttributes = this.getLocalPreference(
      "showDeviceAttributes",
      Object.values(store.getState().devices.device_attributes)
        .filter(o => o.show === true)
        .map(o => {
          return o.key;
        })
    );
    if (showAttributes.length > 0) {
      Object.values(store.getState().devices.device_attributes).map(o => {
        o.show = false;
      });
    }
    // eslint-disable-next-line array-callback-return
    showAttributes.map(k => {
      Object.values(store.getState().devices.device_attributes).map(o => {
        if (o.key === k) {
          o.show = true;
        }
      });
    });
    events.emit(devices.types.CHANGE_DISPLAY_ATTRIBUTES);

    //save display settings
    events.on(devices.types.CHANGE_DISPLAY_ATTRIBUTES, () => {
      this.setLocalPreference(
        "showDeviceAttributes",
        Object.values(store.getState().devices.device_attributes)
          .filter(o => o.show === true)
          .map(o => {
            return o.key;
          })
      );
    });

    //clar notifications
    events.on(events_store.types.CLEAR, () => {
      this.clearToasts();
    });

    //on user update
    events.on(session.types.USER_UPDATE, user => {
      this.user = user;
    });

    //load devices and positions
    events.on(positions.types.LOADED, () => {
      store.dispatch({
        type: app.types.LOADED,
        payload: { locale: this.locale, messages: this.messages }
      });
    });
  }

  // connectToWebsocket() {
  //     if (this._connectionTimeout) {
  //         clearTimeout(this._connectionTimeout);
  //     }
  //     console.log(333,this)
  //     if (this.user) {
  //         this._connectionTimeout = setTimeout(async () => {
  //             try {
  //                 console.log(444)
  //                 store.dispatch({ type: app.types.CONNECTION_CHANGED, payload: 'connecting' })
  //                 events.emit(app.types.CONNECTION_CHANGED, 'connecting');
  //                 await DevicesService.load();
  //                 await PositionsService.load();
  //                 WebSocketService.connect();
  //                 console.log(555)
  //             } catch (ex) {
  //                 this.showError(ex);
  //                 this.connectToWebsocket();
  //             }
  //         }, constants.reconnectTimeout)
  //     }
  // }

  /**
   * Init app, load the resources
   */
  async init() {
    await this.loadMessages(this.locale);
    store.dispatch(alarm_types.actions.loaded(Translator.messages));

    events.on(session.types.LOGIN, async () => {
      store.dispatch({ type: app.types.LOADING });
      this.user = store.getState().session.user;

      this.loadMapPreferences();

      const promises = [
        GroupService.list(),
        GeofencesService.list(),
        DriversService.list(),
        CalendarsService.list(),
        ComputedAttributesService.list(),
        CommandTypesService.list(),
        CommandsService.list(),
        NotificationTypesService.list(),
        NotificationsService.list(),
        DevicesService.load()

        //version 4.4
        //MaintenancesService.list(),
        //NotificatorsService.list(),
      ];

      if (this.getServerVersion() > 4) {
        promises.push(MaintenancesService.list(), NotificatorsService.list());
      }

      //load init data
      await Promise.all(promises).catch(async ex => {
        await this.showError(ex);
        window.location.reload();
      });

      //await PositionsService.load();
      try {
        //WebSocketService.onClose = this.connectToWebsocket;
        WebSocketService.onClose = "close";
        WebSocketService.user = this.user;
        WebSocketService.connect();
      } catch (ex) {
        this.showError(ex);
      }

      this.initRefreshWorker();
    });

    events.on(session.types.LOGOUT, () => {
      this.user = null;
      WebSocketService.user = this.user;
      if (this._connectionTimeout) {
        clearTimeout(this._connectionTimeout);
      }
      WebSocketService.disconnect();
      this.cancelRefreshWorker();
      store.dispatch({ type: app.types.UNLOADED });
    });

    //load server info
    await ServerService.info();
    this.server = Server;

    try {
      //load session
      await SessionService.loadSession();

      //the session is loaded, wait for the devices and positions
      /*events.on(positions.types.LOADED, () => {
                store.dispatch({ type: app.types.LOADED, payload: { locale: this.locale, messages: this.messages } });
            })*/
    } catch (ex) {
      store.dispatch({
        type: app.types.LOADED,
        payload: { locale: this.locale, messages: this.messages }
      });
    }

    //store.dispatch({ type: app.types.LOADED, payload: { locale: this.locale, messages: this.messages } });
  }

  getServerVersion() {
    if (this.server) {
      let regex = /[+-]?\d+(\.\d+)?/g;
      const version = this.server.version.match(regex)[0];
      try {
        return parseFloat(version);
      } catch (ex) {}
      return 0;
    }
    return 0;
  }

  showLoading() {
    this.loadingCount++;
    this._checkLoadingStatus();
  }

  hideLoading() {
    this.loadingCount--;
    this._checkLoadingStatus();
  }

  _checkLoadingStatus() {
    if (this.loadingCount > 0) {
      document.body.classList.add("show-loading-overlay");
    } else {
      this.loadingCount = 0;
      document.body.classList.remove("show-loading-overlay");
    }
  }

  async saveUserPreferences() {
    return await SessionService.updateUser(this.user);
  }

  async updateServer(model) {
    return await ServerService.update(model);
  }

  changeMapPreferences(config) {
    if (config.type === "custom") {
      config.url = this.getPreference(
        "mapUrl",
        constants.mapTypes[constants.selectedMapType].mapServices["default"].url
      );
    } else {
      config.url =
        constants.mapTypes[constants.selectedMapType].mapServices[
          config.type
        ].url;
    }
    config.subdomains =
      constants.mapTypes[constants.selectedMapType].mapServices[
        config.type
      ].subdomains;
    store.dispatch({ type: app.types.MAP_CONFIG, payload: { config } });
    events.emit(app.types.MAP_CONFIG, { config });
  }

  loadMapPreferences() {
    const config = Object.assign({}, store.getState().app.mapstate.map_config);
    let type = this.getPreference("map", "default");

    if (!constants.mapTypes[constants.selectedMapType].mapServices[type]) {
      type = "default";
    }
    config.latitude = this.getPreference("latitude", constants.mapDefaultLat);
    config.longitude = this.getPreference("longitude", constants.mapDefaultLon);
    config.zoom = this.getPreference("zoom", constants.mapDefaultZoom);
    config.maxZoom =
      constants.mapTypes[constants.selectedMapType].mapServices[type].maxZoom;
    config.type = type;
    this.changeMapPreferences(config);
  }

  disconnect() {
    WebSocketService.disconnect();
  }

  connect() {
    WebSocketService.connect();
  }

  toggleConnection() {
    if (WebSocketService.connected) {
      this.disconnect();
    } else {
      this.connect();
    }
  }

  //traccar doesn't do this
  async loadObjects() {
    //await GroupService.list();
    //await DevicesService.list();
  }

  //init refresh worker
  initRefreshWorker() {
    if (this.refreshTimer) {
      this.cancelRefreshWorker();
    }

    /*this.refreshTimer = setTimeout(async () => {
            try {
                //await this.loadObjects();
                const device = new Device().deserialize(store.getState().devices.devices[94]);
                device.lastUpdate = device.lastUpdate + 1;
                device.status = ['online', 'offline', 'unknown'][Math.floor(Math.random() * 3)];
                //store.dispatch({ type: devices.types.UPDATED, payload: { device: device } })
                store.dispatch(devices.actions.updated(device))
                events.emit(devices.types.UPDATED, device);
            } catch (ex) {

            } finally {
                this.initRefreshWorker();
            }

        }, 500);*/
  }

  //cancel refresh worker
  cancelRefreshWorker() {
    try {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer);
      }
    } catch (ex) {}
  }

  async loadMessages(locale) {
    try {
      const { data } = await axios.get("/l10n/" + locale + ".json");
      this.messages = data;
      this.locale = locale;

      Translator.init({ locale: this.locale, messages: this.messages });

      store.dispatch({
        type: localeStore.types.MESSAGES_LOADED,
        payload: { locale: this.locale, messages: this.messages }
      });
      return data;
    } catch (ex) {}
  }

  get isMobile() {
    return window.matchMedia && window.matchMedia("(max-width: 992px)").matches;
  }

  getVehicleFeaturesDisabled() {
    return this.getBooleanAttributePreference("ui.disableVehicleFetures");
  }

  userHasPermission(permission) {
    const admin = this.user.administrator || this.user.admin;
    const manager = this.user.userLimit !== 0;
    const readonly = this.getPreference("readonly", false);
    const deviceReadonly = this.getPreference("deviceReadonly", false);
    switch (permission) {
      case "computed-attributes":
        return (
          admin &&
          !this.getBooleanAttributePreference("ui.disableComputedAttributes")
        );
      case "server":
        return admin;
      case "statics":
        return admin;
      case "users":
        return admin || manager;
      case "calendars":
        return (
          (!this.getBooleanAttributePreference("ui.disableCalendars") &&
            !readonly) ||
          admin
        );
      case "drivers":
        return (
          (!(
            this.getVehicleFeaturesDisabled() ||
            this.getBooleanAttributePreference("ui.disableDrivers")
          ) &&
            !readonly) ||
          admin
        );
      case "commands":
        return (
          (!this.getPreference("limitCommands", false) && !readonly) || admin
        );
      case "maintenance":
        return (
          (!(
            this.getVehicleFeaturesDisabled() ||
            this.getBooleanAttributePreference("ui.disableMaintenance")
          ) &&
            !readonly) ||
          admin
        );
      case "devices":
        return (
          !(this.getVehicleFeaturesDisabled() || readonly || deviceReadonly) ||
          admin
        );
      case "geofences":
      case "notifications":
      case "groups":
        return !readonly || admin;
      case "account":
      case "preferences":
        return !readonly || admin;
      default:
        return true;
    }
  }

  getEventString(eventType) {
    var key = "event" + eventType.charAt(0).toUpperCase() + eventType.slice(1);
    return Translator.translate(key);
  }

  getNotificatorString(eventType) {
    var key =
      "notificator" + eventType.charAt(0).toUpperCase() + eventType.slice(1);
    return Translator.translate(key);
  }

  showReports() {
    /*var rootPanel = Ext.getCmp('rootPanel');
        if (rootPanel) {
            rootPanel.setActiveItem(show ? 1 : 0);
        }*/
  }

  showEvents() {
    /* var rootPanel = Ext.getCmp('rootPanel');
         if (rootPanel) {
             rootPanel.setActiveItem(show ? 2 : 0);
         }*/
  }

  updateNotificationToken(token) {
    if (this.user) {
      const attributes = deepClone(this.user.attributes);
      if (
        !attributes.notificationTokens ||
        attributes.notificationTokens.indexOf(token) < 0
      ) {
        if (!attributes.notificationTokens) {
          attributes.notificationTokens = token;
        } else {
          attributes.notificationTokens += "," + token;
        }
        this.user.attributes = attributes;
        this.saveUserPreferences();
      }
    }
  }

  getPreference(key, defaultValue) {
    if (this.server.forceSettings) {
      return this.server[key] || this.user[key] || defaultValue;
    } else {
      return this.user[key] || this.server[key] || defaultValue;
    }
  }

  getAttributePreference(key, defaultValue) {
    if (this.server.forceSettings) {
      return (
        this.server.attributes[key] || this.user.attributes[key] || defaultValue
      );
    } else {
      return (
        this.user.attributes[key] || this.server.attributes[key] || defaultValue
      );
    }
  }

  getBooleanAttributePreference(key) {
    return this.getAttributePreference(key, false).toString() === "true";
  }

  loadLocalConfig() {
    if (!this._currentLocalConfig) {
      try {
        this._currentLocalConfig =
          JSON.parse(localStorage.getItem(constants.appName + "_settings")) ||
          {};
      } catch (ex) {
        this._currentLocalConfig = {};
      }

      if (typeof this._currentLocalConfig != "object") {
        this._currentLocalConfig = {};
      }
    }
    return this._currentLocalConfig;
  }

  getLocalPreference(key, defaultValue = null) {
    this.loadLocalConfig();
    return this._currentLocalConfig[key] || defaultValue;
  }

  setLocalPreference(key, value) {
    this.loadLocalConfig();
    setAttr(this._currentLocalConfig, key, value);
    localStorage.setItem(
      constants.appName + "_settings",
      JSON.stringify(this._currentLocalConfig)
    );
  }

  getReportColor(deviceId, returnDefault = true) {
    const device = store.getState().devices.devices[deviceId];
    if (device) {
      const reportColor = device.getAttribute("web.reportColor");
      if (reportColor) {
        return reportColor;
      }
      if (returnDefault) {
        const index = deviceId % constants.mapRouteColor.length;
        return constants.mapRouteColor[index];
      }
      return null;
    }
  }

  loadSound(sound) {
    if (!this._sounds[sound]) {
      this._sounds[sound] = new Audio(sound);
      this._sounds.onerror = () => {};
      let promise = this._sounds[sound].play();
      if (promise !== undefined) {
        promise
          .then(() => {
            this._sounds[sound].pause();
          })
          .catch(() => {});
      } else {
        this._sounds[sound].pause();
      }
    }
  }

  playSound(sound) {
    try {
      this.loadSound(sound);
      let promise = this._sounds[sound].play();
      if (promise !== undefined) {
        promise
          .then(() => {
            // Autoplay started!
          })
          .catch(() => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
          });
      }
    } catch (ex) {}
  }

  async showError(error) {
    if (error.response) {
      error = error.response.request;
    }
    let message;
    if (typeof error === "string") {
      message = error;
    } else if (error.responseText) {
      message = error.responseText;
    } else if (error.statusText) {
      message = error.statusText;
    } else if (error.message) {
      message = error.message;
    } else if (error.response && error.response.responseText) {
      message = error.response.responseText;
    } else {
      message = t("errorConnection");
    }
    message = message.split("-")[0];
    const result = await Swal.fire(t("errorTitle"), message, "error");
    return result;
  }

  async showDeleteConfirm() {
    const result = await Swal.fire({
      title: t("sharedRemove"),
      text: t("sharedRemoveConfirm"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#bd362f",
      cancelButtonColor: "#01a0e2"
    });
    return result.value || false;
  }

  async showSuccess(config) {
    let message;
    if (typeof config === "string") {
      message = config;
    } else if (config.responseText) {
      message = config.responseText;
    } else if (config.statusText) {
      message = config.statusText;
    } else {
    }

    const result = Swal.fire("", message, "success");
    return result;
  }

  clearToasts() {
    const toasts = document.getElementsByClassName("iziToast-capsule");
    if (!toasts) {
      return;
    }
    while (toasts.length > 0) toasts[0].remove();
  }

  showToast(message, title, config = {}) {
    if (!this._showNotifications) {
      return;
    }

    if (store.getState().app.notificationstate.enabled) {
      config = Object.assign(
        {
          type: "info",
          timeout: !config.permanent ? constants.notifications.duration : 0,
          animateInside: false,
          position: constants.notifications.position,
          progressBar: !this.isMobile,
          transitionInMobile: "fadeIn",
          transitionOutMobile: "fadeOut"
        },
        config
      );

      config.title = title;
      config.message = message;

      iziToast[config.type](config);

      let maxStack = this.isMobile
        ? constants.notifications.mobileMaxStack
        : constants.notifications.maxStack;
      const toasts = document.getElementsByClassName("iziToast-capsule");
      if (toasts.length > maxStack && maxStack > 0) {
        //iziToast.hide({},  toasts[0]);
        toasts[toasts.length - 1].remove();
      }

      if (config.onClick && toasts[0]) {
        const el = toasts[0].querySelector(".iziToast-texts");
        if (el) {
          el.onclick = e => {
            config.onClick(e);
          };
        }
      }
    }
    if (store.getState().app.notificationstate.soundEnabled) {
      this.playSound(constants.notifications.sound);
    }
  }

  hideNotifications() {
    this._showNotifications = false;
    if (!document.body.classList.contains("hide-notifications")) {
      document.body.classList.add("hide-notifications");
    }
  }

  showNotifications() {
    this._showNotifications = true;
    document.body.classList.remove("hide-notifications");
  }
}

export default new AppService();
