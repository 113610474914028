import React from 'react';
import { t } from '../../utils/Translator';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Events from '../../utils/Events';
import { App } from '../../App';
import { PositionsService } from '../../services';
import { Position, ReportTrip, ReportStop } from '../../models';

/*var CanvasJSReact = require('/assets/js/canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;*/
import { isArray } from 'util';
import { store } from '../../store';

import { CanvasJSChart } from 'assets/js/canvasjs.react';
import * as moment from 'moment';
import { deepClone } from '../../utils/functions';

const pagination = paginationFactory({
    page: 1,
    sizePerPage: 100,
    hideSizePerPage: true,
});


export default class ReportData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportType: null,
            reportData: [],
            toggled: false,
        }

        this.routeColumns = [{
            text: t('reportDeviceName'),
            dataField: 'deviceId',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('deviceId')(row.deviceId);
            },
            headerFormatter: function () {
                return <div>{t('reportDeviceName')}</div>;
            },
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
            //renderer: App.AttributeFormatter.getFormatter('deviceId')
        }, {
            text: t('positionValid'),
            dataField: 'valid',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('valid')(row.valid);
            },
            headerFormatter: function () {
                return <div>{t('positionValid')}</div>;
            },
            style: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            }

            //renderer: App.AttributeFormatter.getFormatter('valid')
        }, {
            text: t('positionFixTime'),
            dataField: 'fixTime',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('fixTime')(row.fixTime);
            },
            headerFormatter: function () {
                return <div>{t('positionFixTime')}</div>;
            },
            style: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            }
            //renderer: App.AttributeFormatter.getFormatter('fixTime')
        }, {
            text: t('positionLatitude'),
            dataField: 'latitude',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('latitude')(row.latitude);
            },
            headerFormatter: function () {
                return <div>{t('positionLatitude')}</div>;
            },
            style: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            }
            //renderer: App.AttributeFormatter.getFormatter('latitude')
        }, {
            text: t('positionLongitude'),
            dataField: 'longitude',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('longitude')(row.longitude);
            },
            headerFormatter: function () {
                return <div>{t('positionLongitude')}</div>;
            },
            style: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            }
            //renderer: App.AttributeFormatter.getFormatter('longitude')
        }, {
            text: t('positionAltitude'),
            dataField: 'altitude',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('altitude')(row.altitude);
            },
            headerFormatter: function () {
                return <div>{t('positionAltitude')}</div>;
            },
            style: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            }
            //renderer: App.AttributeFormatter.getFormatter('altitude')
        }, {
            text: t('positionSpeed'),
            dataField: 'speed',
            sort: true,
            formatter: function (cell, row, rowIndex) {
                return App.AttributeFormatter.getFormatter('speed')(row.speed);
            },
            headerFormatter: function () {
                return <div>{t('positionSpeed')}</div>;
            },
            style: {
                width: '10%',
            },
            headerStyle: {
                width: '10%',
            }
            //renderer: App.AttributeFormatter.getFormatter('speed')
        }, {
            text: t('positionAddress'),
            dataField: 'address',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                if (!row.address) {
                    return <a href="#" onClick={(e) => {
                        e.stopPropagation();
                        formatExtraData.getAddress(row.latitude, row.longitude).then(data => {
                            const p = new Position().deserialize(deepClone(row));
                            p.address = data;
                            Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                        }).catch(ex => App.App.showError(ex))
                    }}>{t('sharedShowAddress')}</a>;
                }
                return App.AttributeFormatter.getFormatter('address')(row.address);
            },
            formatExtraData: this,
            headerFormatter: function () {
                return <div>{t('positionAddress')}</div>;
            },
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }];

        this.eventsColumns = [{
            text: t('positionFixTime'),
            headerFormatter: function () {
                return <div>{t('positionFixTime')}</div>;
            },
            dataField: 'serverTime',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('fixTime')(row.fixTime);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportDeviceName'),
            headerFormatter: function () {
                return <div>{t('reportDeviceName')}</div>;
            },
            dataField: 'deviceId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('deviceId')(row.deviceId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('sharedType'),
            headerFormatter: function () {
                return <div>{t('sharedType')}</div>;
            },
            dataField: 'type',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.App.getEventString(row.type);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('sharedGeofence'),
            headerFormatter: function () {
                return <div>{t('sharedGeofence')}</div>;
            },
            dataField: 'geofenceId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('geofenceId')(row.geofenceId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('sharedMaintenance'),
            headerFormatter: function () {
                return <div>{t('sharedMaintenance')}</div>;
            },
            dataField: 'maintenanceId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('maintenanceId')(row.maintenanceId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }];

        this.tripsColumns = [{
            text: t('reportDeviceName'),
            headerFormatter: function () {
                return <div>{t('reportDeviceName')}</div>;
            },
            dataField: 'deviceId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('deviceId')(row.deviceId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportStartTime'),
            headerFormatter: function () {
                return <div>{t('reportStartTime')}</div>;
            },
            dataField: 'startTime',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('time')(row.startTime);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportStartOdometer'),
            headerFormatter: function () {
                return <div>{t('reportStartOdometer')}</div>;
            },
            dataField: 'startOdometer',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.startOdometer);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportStartAddress'),
            headerFormatter: function () {
                return <div>{t('reportStartAddress')}</div>;
            },
            dataField: 'startAddress',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                if (!row.startAddress) {
                    return <a href="#" onClick={(e) => {
                        e.stopPropagation();
                        formatExtraData.getAddress(row.startLat, row.startLon).then(data => {
                            const p = new ReportTrip().deserialize(deepClone(row));
                            p.startAddress = data;
                            Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                        }).catch(ex => App.App.showError(ex))
                    }}>{t('sharedShowAddress')}</a>;
                }
                return App.AttributeFormatter.getFormatter('address')(row.startAddress);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEndTime'),
            headerFormatter: function () {
                return <div>{t('reportEndTime')}</div>;
            },
            dataField: 'endTime',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('time')(row.endTime);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEndOdometer'),
            headerFormatter: function () {
                return <div>{t('reportEndOdometer')}</div>;
            },
            dataField: 'endOdometer',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.endOdometer);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEndAddress'),
            headerFormatter: function () {
                return <div>{t('reportEndAddress')}</div>;
            },
            dataField: 'endAddress',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                if (!row.endAddress) {
                    return <a href="#" onClick={(e) => {
                        e.stopPropagation();
                        formatExtraData.getAddress(row.endLat, row.endLon).then(data => {
                            const p = new ReportTrip().deserialize(deepClone(row));
                            p.endAddress = data;
                            Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                        }).catch(ex => App.App.showError(ex))
                    }}>{t('sharedShowAddress')}</a>;
                }
                return App.AttributeFormatter.getFormatter('address')(row.endAddress);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('sharedDistance'),
            headerFormatter: function () {
                return <div>{t('sharedDistance')}</div>;
            },
            dataField: 'distance',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.distance);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportAverageSpeed'),
            headerFormatter: function () {
                return <div>{t('reportAverageSpeed')}</div>;
            },
            dataField: 'averageSpeed',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('speeed')(row.averageSpeed);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportMaximumSpeed'),
            headerFormatter: function () {
                return <div>{t('reportMaximumSpeed')}</div>;
            },
            dataField: 'maxSpeed',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('speeed')(row.maxSpeed);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportDuration'),
            headerFormatter: function () {
                return <div>{t('reportDuration')}</div>;
            },
            dataField: 'duration',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('duration')(row.duration);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportSpentFuel'),
            headerFormatter: function () {
                return <div>{t('reportSpentFuel')}</div>;
            },
            dataField: 'spentFuel',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('sharedDriver'),
            headerFormatter: function () {
                return <div>{t('sharedDriver')}</div>;
            },
            dataField: 'driverUniqueId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('driverUniqueId')(row.driverUniqueId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }];

        this.stopsColumns = [{
            text: t('reportDeviceName'),
            headerFormatter: function () {
                return <div>{t('reportDeviceName')}</div>;
            },
            dataField: 'deviceId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('deviceId')(row.deviceId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportStartTime'),
            headerFormatter: function () {
                return <div>{t('reportStartTime')}</div>;
            },
            dataField: 'startTime',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('time')(row.startTime);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('positionOdometer'),
            headerFormatter: function () {
                return <div>{t('positionOdometer')}</div>;
            },
            dataField: 'startOdometer',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.startOdometer);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('positionAddress'),
            dataField: 'address',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                /*if (!row.address) {
                    return <a href="#" onClick={(e) => {
                        e.stopPropagation();
                        formatExtraData.getAddress(row.lat, row.endLon).then(data => {
                            const p = new ReportStop().deserialize(deepClone(row));
                            p.address = data;
                            Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                        }).catch(ex => App.App.showError(ex))
                    }}>{t('sharedShowAddress')}</a>;
                }*/
                return App.AttributeFormatter.getFormatter('address')(row.address);
            },
            formatExtraData: this,
            headerFormatter: function () {
                return <div>{t('positionAddress')}</div>;
            },
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEndTime'),
            headerFormatter: function () {
                return <div>{t('reportEndTime')}</div>;
            },
            dataField: 'endTime',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('time')(row.endTime);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportDuration'),
            headerFormatter: function () {
                return <div>{t('reportDuration')}</div>;
            },
            dataField: 'duration',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('duration')(row.duration);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEngineHours'),
            headerFormatter: function () {
                return <div>{t('reportEngineHours')}</div>;
            },
            dataField: 'engineHours',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('duration')(row.engineHours);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportSpentFuel'),
            headerFormatter: function () {
                return <div>{t('reportSpentFuel')}</div>;
            },
            dataField: 'spentFuel',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }];

        this.summaryColumns = [{
            text: t('reportDeviceName'),
            headerFormatter: function () {
                return <div>{t('reportDeviceName')}</div>;
            },
            dataField: 'deviceId',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('deviceId')(row.deviceId);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('sharedDistance'),
            headerFormatter: function () {
                return <div>{t('sharedDistance')}</div>;
            },
            dataField: 'distance',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.distance);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportStartOdometer'),
            headerFormatter: function () {
                return <div>{t('reportStartOdometer')}</div>;
            },
            dataField: 'startOdometer',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.startOdometer);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEndOdometer'),
            headerFormatter: function () {
                return <div>{t('reportEndOdometer')}</div>;
            },
            dataField: 'endOdometer',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('distance')(row.endOdometer);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportAverageSpeed'),
            headerFormatter: function () {
                return <div>{t('reportAverageSpeed')}</div>;
            },
            dataField: 'averageSpeed',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('speed')(row.averageSpeed);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportMaximumSpeed'),
            headerFormatter: function () {
                return <div>{t('reportMaximumSpeed')}</div>;
            },
            dataField: 'maxSpeed',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('speed')(row.maxSpeed);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportEngineHours'),
            headerFormatter: function () {
                return <div>{t('reportEngineHours')}</div>;
            },
            dataField: 'engineHours',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('duration')(row.engineHours);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }, {
            text: t('reportSpentFuel'),
            headerFormatter: function () {
                return <div>{t('reportSpentFuel')}</div>;
            },
            dataField: 'spentFuel',
            sort: true,
            formatter: function (cell, row, rowIndex, formatExtraData) {
                return App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel);
            },
            formatExtraData: this,
            style: {
                width: '20%',
            },
            headerStyle: {
                width: '20%',
            }
        }];


        this.onData = this.onData.bind(this);
        this.onShow = this.onShow.bind(this);
        this.renderData = this.renderData.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.updateTableRow = this.updateTableRow.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentWillMount() {
        Events.on('reports/CLEAR', this.onClear)
        Events.on('reports/DATA', this.onData)
        Events.on('reports/SHOW', this.onShow)
        Events.on('reports/UPDATE_POSITION', this.updateTableRow)
    }

    componentWillUnmount() {
        Events.off('reports/CLEAR', this.onClear)
        Events.off('reports/DATA', this.onData)
        Events.off('reports/SHOW', this.onShow)
        Events.off('reports/UPDATE_POSITION', this.updateTableRow)
    }



    async onClear() {
        this.setState({ reportData: [] })
    }

    async onData(data) {
        let reportData = [];
        const positionIds = [];

        switch (this.state.reportType.key) {
            case 'events':
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        positionIds.push(d.positionId);
                        d.id = d.positionId;
                        reportData.push(d);
                    })
                });
                if (positionIds.length > 0) {
                    const mapData = Object.assign({}, data);
                    const positions = await PositionsService.list({ id: positionIds });
                    const mapPositions = {};
                    positions.map(p => {
                        if (!mapPositions[p.deviceId]) {
                            mapPositions[p.deviceId] = [];
                        }
                        mapPositions[p.deviceId].push(p);
                    });
                    mapData.data = mapPositions;
                    mapData.config.showRoutes = false;
                    Events.emit('reports/MAP_DATA', mapData);
                }
                break;
            case 'trips':
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        d.id = d.startPositionId;
                        reportData.push(d);
                    })
                });
                break;
            case 'stops':
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        positionIds.push(d.positionId);
                        d.id = d.positionId;
                        reportData.push(d);
                    })
                });
                if (positionIds.length > 0) {
                    const mapData = Object.assign({}, data);
                    const positions = await PositionsService.list({ id: positionIds });
                    const mapPositions = {};
                    positions.map(p => {
                        if (!mapPositions[p.deviceId]) {
                            mapPositions[p.deviceId] = [];
                        }
                        if (!p.address) {
                            p.address = reportData.find(o => o.id === p.id).address;
                        }
                        mapPositions[p.deviceId].push(p);
                    });
                    mapData.data = mapPositions;
                    mapData.config.showRoutes = false;
                    Events.emit('reports/MAP_DATA', mapData);
                }
                break;
            case 'summary':
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        reportData.push(d);
                    })
                });
                break;
            case 'chart':
                reportData = data;
                data.config.showRoutes = true;
                Events.emit('reports/MAP_DATA', data);
                break;
            default:
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        reportData.push(d);
                    })
                });
                data.config.showRoutes = true;
                Events.emit('reports/MAP_DATA', data);
                break;
        }


        this.setState({
            reportData: reportData
        })

    }

    onShow(config) {
        this.setState({
            reportType: config.reportType
        })
    }

    getAddress(lat, lng) {
        try {
            return PositionsService.findAddressFromLatLng(lat, lng);
        } catch (ex) {
            App.App.showError(ex);
        }
    }

    getAddressByPositionId(position) {
        try {
            return PositionsService.findAddressFromPosition(position);
        } catch (ex) {
            App.App.showError(ex);
        }
    }

    renderChart() {
        if (isArray(this.state.reportData)) {
            return null;
        }
        this.data = [];
        const reportData = this.state.reportData;


        const title = (t('position' + reportData.config.chartType.ucFirst()));

        Object.keys(reportData.data).map(k => {
            const device = store.getState().devices.devices[k];
            if (device) {
                const d = {
                    type: "line",
                    //xValueType: 'dateTime',
                    dataPoints: [],
                    id: k,
                    color: App.App.getReportColor(k),
                    click: (e) => {
                        Events.emit('reports/SELECT_POSITION', e.dataPoint.id);
                    }
                }



                reportData.data[k].map(p => {
                    let value = null;
                    switch (reportData.config.chartType) {
                        case 'distance':
                        case 'speed':
                        case 'altitude':
                        case 'accuracy':
                        case 'course':
                            value = p.getProperty(reportData.config.chartType);
                            break;
                        default:
                            value = p.getAttribute(reportData.config.chartType);
                    }
                    d.dataPoints.push({
                        x: p.fixTime,
                        y: value,
                        label: moment(p.fixTime).format('YYYY-MM-DD HH:mm:ss'),
                        id: p.id,
                    })
                })
                this.data.push(d);
            }
        });

        /*
        reportData.data.map(p => {
            if (!this.data[p.deviceId]) {
                this.data[p.deviceId] = [];
                this.columns.push(store.getState().devices.devices[p.deviceId].name);
            }
    
        });*/
        const options = {
            axisX: {
                valueFormatString: 'YYYY-MM-DD \n HH:mm:ss',
                gridThickness: 1,
                labelAngle: 0,
            },
            axisY: {
                title: title,
            },
            exportEnabled: true,
            zoomEnabled: true, 
            data: this.data,
        }
        /*
        const CanvasJSChart = CanvasJSReact.CanvasJSChart;*/

        return <CanvasJSChart options={options} />
    }

    updateTableRow(payload) {
        const newState = [...this.state.reportData];
        const index = this.state.reportData.findIndex(o => o.id === payload.id);
        if (index > -1) {
            if (this.state.reportType.key === 'route') {
                const p = new Position().deserialize(deepClone(payload.data));
                newState[index] = p;
                this.setState({ reportData: newState });
            } else if (this.state.reportType.key === 'trips') {
                newState[index] = payload.data;
                this.setState({ reportData: newState });
            }

        }
    }

    renderData() {
        if (!this.state.reportType) {
            return null;
        }


        if (this.state.reportType.key === 'chart') {
            return this.renderChart();
        } else {

            let columns = [];

            switch (this.state.reportType.key) {
                case 'route':
                    columns = this.routeColumns;
                    break;
                case 'events':
                    columns = this.eventsColumns;
                    break;
                case 'trips':
                    columns = this.tripsColumns;
                    break;
                case 'stops':
                    columns = this.stopsColumns;
                    break;
                case 'summary':
                    columns = this.summaryColumns;
                    break;
            }


            const rowEvents = {
                onClick: async (e, row, rowIndex) => {
                    if (row.selected) {
                        return;
                    }
                    const newData = [...this.state.reportData];
                    newData.map(d => {
                        d.selected = false;
                    });
                    newData[rowIndex].selected = true;
                    this.setState({
                        reportData: [...this.state.reportData]
                    })

                    if (row instanceof Position || row instanceof ReportStop) {

                        if (this.state.reportType.key === 'route' ||
                            this.state.reportType.key === 'events' ||
                            this.state.reportType.key === 'stops') {
                            Events.emit('reports/SELECT_POSITION', row.id);
                        } else if (this.state.reportType.key === 'summary') {
                        } else {
                            App.App.showLoading();
                            try {
                                const positions = await PositionsService.list({
                                    from: new Date(row.startTime).toISOString(),
                                    to: new Date(row.endTime).toISOString(),
                                    deviceId: row.deviceId,
                                });
                                Events.emit('reports/MAP_DATA', {
                                    data: {
                                        [row.deviceId]: positions
                                    },
                                    config: {
                                        showRoutes: true,
                                        showMarkers: true,
                                    }
                                });
                            } catch (ex) {

                            } finally {
                                App.App.hideLoading();
                            }
                        }
                    } else if (row instanceof ReportTrip) {
                        App.App.showLoading();
                        try {
                            const positions = await PositionsService.list({
                                from: new Date(row.startTime).toISOString(),
                                to: new Date(row.endTime).toISOString(),
                                deviceId: row.deviceId,
                            });
                            Events.emit('reports/MAP_DATA', {
                                data: {
                                    [row.deviceId]: positions
                                },
                                config: {
                                    showRoutes: true,
                                    showMarkers: true,
                                }
                            });
                        } catch (ex) {

                        } finally {
                            App.App.hideLoading();
                        }
                    }
                }
            };

            const rowClasses = (row, rowIndex) => {
                if (row.selected) {
                    return 'selected';
                }
            };


            return <BootstrapTable bootstrap4={true} classes="table table-sm table-condensed table-hover" rowClasses={rowClasses} rowEvents={rowEvents} keyField='id' data={this.state.reportData} columns={columns} pagination={pagination} />
        }
    }

    render() {
        return <React.Fragment>

            <div className="data-wrapper">
                <div className="table-wrapper">
                    <div className="table-fixed">
                        {this.renderData()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}