import { BaseModel } from "./BaseModel";

export const fields = [{
    name: 'id',
    type: 'int'

}, {
    name: 'name',
    type: 'string'
}, {
    name: 'data'
}, {
    name: 'attributes'
}];

export class Calendar extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}