import { BaseModel } from "./BaseModel";

export const fields = [{
    name: 'deviceId',
    type: 'int'
}, {
    name: 'deviceName',
    type: 'string'
}, {
    name: 'maxSpeed',
    type: 'float',
    //convert: Traccar.AttributeFormatter.getConverter('speed')
}, {
    name: 'averageSpeed',
    type: 'float',
    //convert: Traccar.AttributeFormatter.getConverter('speed')
}, {
    name: 'distance',
    type: 'float',
    //convert: Traccar.AttributeFormatter.getConverter('distance')
}, {
    name: 'startOdometer',
    type: 'float',
    //convert: Traccar.AttributeFormatter.getConverter('distance')
}, {
    name: 'endOdometer',
    type: 'float',
    //convert: Traccar.AttributeFormatter.getConverter('distance')
}, {
    name: 'duration',
    type: 'int'
}, {
    name: 'startTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'startAddress',
    type: 'string'
}, {
    name: 'endTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'endAddress',
    type: 'string'
}, {
    name: 'spentFuel',
    type: 'float',
    //convert: Traccar.AttributeFormatter.getConverter('spentFuel')
}, {
    name: 'driverUniqueId',
    type: 'string'
}, {
    name: 'driverName',
    type: 'string'
}];

export class ReportTrip extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}