import React from 'react';
import events from '../../utils/Events';
import { t } from '../../utils/Translator';
import constants from '../../config/constants';
import { App } from '../../App';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { AppService, PositionsService, DevicesService } from '../../services';
import { Scrollbars } from 'react-custom-scrollbars';
import { store } from '../../store';
import app from '../../store/app';
import CommandModal from './command';
import Events from '../../utils/Events';
import devices from '../../store/devices';
import positions from '../../store/positions';
import { Position } from '../../models';

export default class DeviceStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            currentTab: 'sensors',
            menuOpen: true
        };
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.hideCommand = this.hideCommand.bind(this);


        /*if (App.App.user.administrator ||
            !App.App.user.deviceReadonly && !App.App.getPreference('readonly', false)) {

            this.lookupReference('computedAttributesButton').setDisabled(
                Traccar.app.getBooleanAttributePreference('ui.disableComputedAttributes'));
        }*/
        const hideAttributesPreference = App.App.getAttributePreference('ui.hidePositionAttributes');
        this.hideAttributes = {};
        if (hideAttributesPreference) {
            const attributesList = hideAttributesPreference.split(/[ ,]+/).filter(Boolean);
            attributesList.map(v => {
                this.hideAttributes[v] = true;
            })
        }
        this.getAddress = this.getAddress.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

    }

    componentWillReceiveProps(newProps, newState) {
        if (newProps.open !== undefined) {
            this.setState({ open: newProps.open })
        }
    }

    toggle() {
        this.setState({ toggled: !this.state.toggled, menuOpen: false })
    }

    open() {
        this.setState({ toggled: true, menuOpen: false })
    }

    close() {
        this.setState({ toggled: false, menuOpen: false })
    }

    componentWillMount() {
        events.on('CLOSE_DEVICESTATUS', this.close);
        events.on('OPEN_DEVICESTATUS', this.open);
        //events.on(devices.types.DEVICE_SELECTED, this.open);
    }

    componentWillUnmount() {
        events.off('CLOSE_DEVICESTATUS', this.close)
        events.off('OPEN_DEVICESTATUS', this.open);
        //events.off(devices.types.DEVICE_SELECTED, this.open);
    }

    renderControl() {
        return (
            <div className="control" onClick={this.toggle}>
                <i className="mdi button"></i>
            </div>
        )
    }

    getAttributes() {
        const { device, position } = this.props;
        return (() => {
            const list = constants.position_attributes;
            const result = {};
            list.map(a => {
                result[a] = {
                    name: t('position' + a.replace(/^\w/g, function (s) {
                        return s.toUpperCase();
                    })),
                    key: a,
                    value: position.hasOwnProperty(a) ? position.getFormattedProperty(a) : undefined
                };
            })
            return result;
        })();
    }


    renderAttribute(attr) {
        return (
            <div className="device-attribute" key={attr.key}>
                <div className="name">{attr.name}</div>
                <div className="value">
                    {(() => {
                        switch (attr.key) {
                            case 'address':
                                if (!attr.value) {
                                    return <React.Fragment><span><a className="link" onClick={this.getAddress}>{t('sharedShowAddress')}</a></span> <a href={`https://www.google.com/maps?q=${this.props.position.latitude},${this.props.position.longitude}&t=m&hl=${AppService.locale}`} target="_blank" className="btn btn-xs btn-secondary"><i className="mdi mdi-google-maps"></i></a></React.Fragment>
                                } else {
                                    return <React.Fragment><span dangerouslySetInnerHTML={{ __html: attr.value }}></span> <a href={`https://www.google.com/maps?q=${this.props.position.latitude},${this.props.position.longitude}&t=m&hl=${AppService.locale}`} target="_blank" className="btn btn-xs btn-secondary"><i className="mdi mdi-google-maps"></i></a></React.Fragment>
                                }
                            default:
                                return <React.Fragment><span dangerouslySetInnerHTML={{ __html: attr.value }}></span></React.Fragment>
                        }
                    })()}

                </div>
            </div>
        );
    }

    renderDetails() {
        const { position } = this.props;
        if (position) {
            const attrs = this.getAttributes();
            const ret = [];
            if (attrs) {
                Object.values(attrs).map(a => {
                    ret.push(this.renderAttribute(a));
                })
            }
            const positionAttributes = position.attributes;
            if (positionAttributes instanceof Object) {
                Object.keys(positionAttributes).map(key => {
                    if (positionAttributes.hasOwnProperty(key) && !this.hideAttributes[key]) {
                        let value = position.hasAttribute(key) ? App.AttributeFormatter.getAttributeFormatter(key)(position.getAttribute(key)) : App.AttributeFormatter.defaultFormatter(positionAttributes[key]);
                        if (key === 'alarm' && value) {
                            value = t('alarm' + value.ucFirst());
                        } else if (key === 'event') {
                            //value = App.App.getEventString(value);
                        }
                        //console.log(key, value);
                        ret.push(this.renderAttribute({
                            key: key,
                            name: App.PositionAttributes.getAttributeName(key),
                            value: value
                        }))
                    }
                })
            }

            return ret;
        }


    }

    renderStreetView() {
        const { position } = this.props;
        if (position && constants.enableStreetView && AppService.getBooleanAttributePreference('web.enableStreetView') && AppService.getAttributePreference('web.streetViewApiKey')) {
            const imgUrl = `https://maps.googleapis.com/maps/api/streetview?size=500x300&key=${AppService.getAttributePreference('web.streetViewApiKey')}&location=${position.latitude},${position.longitude}&heading=${position.course}`;
            return (<div className="googleStreetView flex-grow-0">
                <a href={`http://maps.google.com/maps?q=&layer=c&cbll=${position.latitude},${position.longitude}&cbp=11,${position.course},0,0,0`} target="_blank" style={{ backgroundImage: `url(${imgUrl})` }}></a>
            </div>)
        }
        return null;
    }


    renderSensors() {
        const { device, position } = this.props;
        return (
            <React.Fragment>
                <div className="row" style={{ width: '99%' }}>
                    <div className="col-12">
                        <div className="device-sensor">
                            <span className={`name`}>{t('positionAddress')}</span>
                            <span className="value">
                                {(() => {
                                    if (!position.address) {
                                        return <React.Fragment><span><a className="link" onClick={this.getAddress}>{t('sharedShowAddress')}</a></span> <a href={`https://www.google.com/maps?q=${position.latitude},${position.longitude}&t=m&hl=${AppService.locale}`} target="_blank" className="btn btn-xs btn-secondary"><i className="mdi mdi-google-maps"></i></a></React.Fragment>
                                    } else {
                                        return <React.Fragment><span dangerouslySetInnerHTML={{ __html: position.address }}></span> <a href={`https://www.google.com/maps?q=${position.latitude},${position.longitude}&t=m&hl=${AppService.locale}`} target="_blank" className="btn btn-xs btn-secondary"><i className="mdi mdi-google-maps"></i></a></React.Fragment>
                                    }
                                })()}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ width: '99%' }}>
                    <div className="col-12 col-sm-6">
                        <div className="device-sensor">
                            <span className="name">
                                <span className={`sensor device-status online-status ${device.status}`}><i className="mdi mdi-satellite-variant"></i></span>
                                {t('deviceStatus')}
                            </span>
                            <span className="value">{t('deviceStatus' + device.status.ucFirst())}</span>
                        </div>
                        <div className="device-sensor">
                            <span className="name">
                                <span className={'sensor device-status motion-status ' + (position && position.hasAttribute('motion') ? (position.getAttribute('motion') ? 'moving' : 'stopped') : 'unknown')} title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i className="mdi mdi-run-fast"></i></span>
                                {t('positionMotion')}
                            </span>
                            <span className="value"> {position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}</span>
                        </div>
                        <div className="device-sensor">
                            <span className="name">
                                <span className={'sensor device-status speed-status ' + (position && position.speed > 0 ? 'moving' : 'stopped')} title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i className="mdi mdi-speedometer"></i></span>
                                {t('positionSpeed')}
                            </span>
                            <span className="value">
                                {position.getFormattedProperty('speed')}
                            </span>
                        </div>
                        {position && position.hasAttribute('odometer') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status odometer-status '} title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i className="mdi mdi-counter"></i></span>
                                    {t('positionOdometer')}
                                </span>
                                <span className="value">
                                    {App.AttributeFormatter.getFormatter('distance')(position.getAttribute('odometer'))}
                                </span>
                            </div> : null}

                    </div>
                    <div className="col-12 col-sm-6">
                        {position && position.hasAttribute('result') && false ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status text-dark'} title={position && position.getAttribute('result') ? position.getAttribute('result') : ''}><i className="mdi mdi-counter"></i></span>
                                    {t('eventCommandResult')}
                                </span>
                                <span className="value">
                                    {position.getAttribute('result')}
                                </span>
                            </div> : null}

                        {position && position.hasAttribute('hours') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status text-dark '}><i className="mdi mdi-clock"></i></span>
                                    {t('positionHours')}
                                </span>
                                <span className="value">
                                    {position.getFormattedAttribute('hours')}
                                </span>
                            </div> : null}
                        {position && position.hasAttribute('batteryLevel') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status battery-status ' + (position.hasAttribute('batteryLevel') ? (() => {
                                        if (position.getAttribute('batteryLevel') > 50) {
                                            return 'text-success';
                                        } else if (position.getAttribute('batteryLevel') > 30) {
                                            return 'text-warning';
                                        } else {
                                            return 'text-danger'
                                        }
                                    })() : '')}><i className={'mdi ' + (() => {
                                        if (position.getAttribute('batteryLevel') > 50) {
                                            return 'mdi-battery';
                                        } else if (position.getAttribute('batteryLevel') > 30) {
                                            return 'mdi-battery-30';
                                        } else {
                                            return 'mdi-battery-10'
                                        }
                                    })()}></i></span>
                                    {t('positionBattery')}
                                </span>
                                <span className="value" dangerouslySetInnerHTML={{ __html: position.getFormattedAttribute('batteryLevel') }}>
                                </span>
                            </div> : null}
                        {
                            position && position.hasAttribute('ignition') ?
                                <React.Fragment>
                                    <div className="device-sensor">
                                        <span className="name">
                                            <span className={'sensor device-status ignition-status attribute ' + (position && position.hasAttribute('ignition') ? (position.getAttribute('ignition') ? 'on' : 'off') : 'unknown')} title={position && position.hasAttribute('ignition') ? (position.getAttribute('ignition') ? 'on' : 'off') : 'unknown'}><i className="mdi mdi-engine-outline"></i></span>
                                            {t('positionIgnition')}
                                        </span>
                                        <span className="value">
                                            {App.AttributeFormatter.getFormatter('boolean')(position.getAttribute('ignition')).toString()}
                                        </span>
                                    </div>
                                </React.Fragment>
                                : null

                        }
                    </div>
                </div>
            </React.Fragment >
        )
    }

    selectTab(name) {
        this.setState({ currentTab: name })
    }

    toggleMenu() {
        this.setState({ menuOpen: !this.state.menuOpen })
    }
    showEdit(e) {
        e.stopPropagation();
        store.dispatch(app.actions.showModal({ name: 'device-form', icon: 'mdi mdi mdi-car', title: t('sharedEdit') + ' ' + t('deviceTitle') }, { item: this.props.device, onSave: this.save }))
    }

    async save(item) {
        try {
            await DevicesService.update(item);
            store.dispatch(app.actions.hideModal());
            store.dispatch(devices.actions.updated(item));
            Events.emit(devices.types.UPDATED, item)
            store.dispatch(app.actions.hideModal());
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    showCommand(e) {
        e.stopPropagation();
        this.setState({ showCommand: true })
    }

    hideCommand() {
        this.setState({ showCommand: false });
    }

    renderCommandForm() {
        const { device, position } = this.props;
        return <CommandModal onHide={this.hideCommand} device={device} position={position} />
    }

    renderContent() {
        const { device, position } = this.props;
        return (
            <React.Fragment>
                {this.state.showCommand ? this.renderCommandForm() : null}
                <div className="header flex-grow-0 d-flex flex-row">
                    <h1 className="title flex-grow-1">
                        {(process.env.NODE_ENV !== 'production') ? device.id + ' - ' : null}{device.name}
                    </h1>
                    <div className="controls  flex-grow-0">
                        <button onClick={() => this.selectTab('sensors')} className={"btn btn-secondary " + (this.state.currentTab === 'sensors' ? 'active' : '')}><i className="mdi mdi-router-wireless"></i></button>
                        <button onClick={() => this.selectTab('position')} className={"btn btn-secondary " + (this.state.currentTab === 'position' ? 'active' : '')}><i className="mdi mdi-format-align-justify"></i></button>
                        <button onClick={() => this.selectTab('streetView')} className={"btn btn-secondary d-md-none " + (this.state.currentTab === 'streetView' ? 'active' : '')}><i className="mdi mdi-camera"></i></button>

                    </div>
                    <div className="controls flex-grow-0">
                        <DropdownButton
                            drop='up'
                            variant="secondary"
                            title={<i className="mdi mdi-settings"></i>}

                        >
                            {App.App.userHasPermission('devices') ? <Dropdown.Item onClick={(e) => { this.showEdit(e) }}>
                                <i className="mdi mdi-pencil"></i> {t('sharedEdit')}
                            </Dropdown.Item> : null}
                            <Dropdown.Item onClick={(e) => { this.showCommand(e) }}>
                                <i className="mdi mdi-apple-keyboard-command"></i> {t('commandTitle')}
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                <div className="content flex-grow-1 row">
                    {device && position ?
                        <React.Fragment>
                            <div className={"details d-tab col-12 col-md-8 p-1 " + (this.state.currentTab === 'sensors' ? 'active' : '')}>
                                <Scrollbars>
                                    {this.renderSensors()}
                                </Scrollbars>
                            </div>
                            <div className={"details d-tab col-12 col-md-8 p-1 " + (this.state.currentTab === 'position' ? 'active' : '')}>
                                <Scrollbars>
                                    {this.renderDetails()}
                                </Scrollbars>
                            </div>
                            <div className={"details d-street col-12 col-md-4 p-0 " + (this.state.currentTab === 'streetView' ? 'active' : '')}>
                                {this.renderStreetView()}
                            </div>
                        </React.Fragment> : (
                            <div className="alert alert-warning col-12 flex-grow-0" style={{ height: '3em' }}>Position not loaded</div>
                        )}
                </div>
            </React.Fragment>

        )
    }

    async getAddress() {
        try {
            const address = await PositionsService.findAddressFromLatLng(this.props.position.latitude, this.props.position.longitude);
            const newPosition = new Position().deserialize(this.props.position);
            newPosition.address = address;
            store.dispatch(positions.actions.updated(newPosition));
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    render() {
        const { device } = this.props;
        return (
            <div className={'device-attributes d-flex flex-column app-bottombar ' + (this.state.toggled || !device ? 'toggled' : '') + ' ' + (this.props.className || '')}>
                {device ? this.renderContent() : null}
                {(this.props.controlled !== undefined || !this.props.controlled ? this.renderControl() : null)}
            </div>
        )
    }
}