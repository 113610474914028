import React from 'react';
import { Row, Button, Form, FormGroup, FormLabel, FormControl, InputGroup } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { AppService } from '../../../services';
import constants from '../../../config/constants';
import { store } from '../../../store';
import { deepClone, isEqual } from '../../../utils/functions';
import { Server } from '../../../models/Server';
import { Server as ServerInstance } from '../../../models';
import AttributesPage from '../Attributes';
import Collapse from '../../Collapse';
import CustomSelect from '../../Controls/CustomSelect';
import CustomCheckbox from '../../Controls/CustomCheckBox';

class ServerPreferencesPage extends React.Component {
    constructor(props) {
        super(props);



        const mapOptions = [];
        Object.keys(constants.mapTypes[constants.selectedMapType].mapServices).map(i => {
            const c = constants.mapTypes[constants.selectedMapType].mapServices[i];
            if (c.enabled) {
                mapOptions.push({ label: t(c.name), value: i, config: c });
            }

        });

        const coordinateFormats = [];
        const coordinate_formats = store.getState().coordinate_formats.coordinate_formats;
        Object.values(coordinate_formats).map(o => {
            coordinateFormats.push({ label: t(o.name), value: o.key })
        });


        this.state = {
            attributesPage: false,
            sending: false,
            mapOptions: mapOptions,
            coordinateFormats: coordinateFormats,
            changed: false,
            server: new Server().deserialize(deepClone(props.server))
        };




        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getMapState = this.getMapState.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
    }

    handleClose(modal) {
        this.setState({ [modal]: false })
    }

    async handleSubmit(data, { setSubmitting }) {
        setSubmitting(true);
        try {
            const { server } = this.state;
            server.deserialize(data);
            await AppService.updateServer(server);
            this.props.onHide();
        } catch (ex) {
            AppService.showError(ex);
        } finally {
            setSubmitting(false);
        }

    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new Server().deserialize(deepClone(this.props.server));
        const server = this.state.server;
        server.attributes = clone.attributes;
        this.setState({
            ...this.state,
            server
        })
        this.handleClose('attributesPage')
    }

    onAttributesClosed() {
        this.setState({ changed: !isEqual(this.state.server.attributes, this.props.server.attributes) })
        this.handleClose('attributesPage')
    }


    getMapState(setFieldValue) {
        const state = store.getState().app.mapstate;
        setFieldValue('zoom', state.zoom)
        setFieldValue('latitude', state.latitude)
        setFieldValue('longitude', state.longitude)
    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({ server: new Server().deserialize(deepClone(newProps.server)) })
    }
    render() {
        const { sending, coordinateFormats, mapOptions, server } = this.state;

        const schema = yup.object({
            phone: yup.string(),
            mapLayer: yup.string(),
            latitude: yup.number(),
            longitude: yup.number(),
            zoom: yup.number().min(1),
            twelveHourFormat: yup.bool(),
            coordinateFormat: yup.string(),
            poiLayer: yup.string(),
        });
        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={server} type="userAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    disabled={sending}
                    initialValues={{
                        mapUrl: server.mapUrl.replace(/&amp;/g, '&'),
                        mapLayer: server.map,
                        latitude: server.latitude,
                        longitude: server.longitude,
                        zoom: server.zoom,
                        twelveHourFormat: server.twelveHourFormat,
                        coordinateFormat: server.coordinateFormat,
                        poiLayer: server.poiLayer.replace(/&amp;/g, '&'),
                        forceSettings: server.forceSettings,
                        registration: server.registration,
                        readonly: server.readonly,
                        deviceReadonly: server.deviceReadonly,
                        limitCommands: server.limitCommands,
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid,
                        setFieldValue
                    }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <FormGroup style={{ zIndex: 1 }}>
                                                <FormLabel>{t("mapLayer")}</FormLabel>
                                                <CustomSelect options={mapOptions} onChange={setFieldValue} defaultValue={values.mapLayer} name="map" />


                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("mapCustom")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="mapUrl"
                                                    value={values.mapUrl}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.mapUrl}
                                                />
                                            </FormGroup>
                                            <Row>
                                                <FormGroup className="col-4">
                                                    <FormLabel>{t("positionLatitude")}</FormLabel>
                                                    <FormControl
                                                        type="number"
                                                        name="latitude"
                                                        value={values.latitude}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.latitude}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="col-4">
                                                    <FormLabel>{t("positionLongitude")}</FormLabel>
                                                    <FormControl
                                                        type="number"
                                                        name="longitude"
                                                        value={values.longitude}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.longitude}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="col-4">
                                                    <FormLabel>{t("serverZoom")}</FormLabel>
                                                    <InputGroup>
                                                        <FormControl
                                                            type="number"
                                                            name="zoom"
                                                            min="1"
                                                            className="d-inline-block"
                                                            value={values.zoom}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.zoom}
                                                        />
                                                        <InputGroup.Append>
                                                            <Button style={{ zIndex: 0 }} onClick={() => this.getMapState(setFieldValue)} title={t('sharedGetMapState')}><i className="mdi mdi-map-marker"></i></Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup className="col-12 col-md-6">
                                                    <FormLabel>{t("settingsCoordinateFormat")}</FormLabel>
                                                    <CustomSelect options={coordinateFormats} onChange={setFieldValue} defaultValue={values.coordinateFormat} name="coordinateFormat" />
                                                </FormGroup>
                                                <FormGroup className="col-12 col-md-6">
                                                    <FormLabel>{t("mapPoiLayer")}</FormLabel>
                                                    <FormControl
                                                        type="string"
                                                        name="poiLayer"
                                                        value={values.poiLayer}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.poiLayer}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup className="col-6">
                                                    <CustomCheckbox name="twelveHourFormat" value="twelveHourFormat" defaultChecked={values.twelveHourFormat} onChange={handleChange} label={t('settingsTwelveHourFormat')} />

                                                </FormGroup>

                                                <FormGroup className="col-6">
                                                    <CustomCheckbox name="forceSettings" value="forceSettings" defaultChecked={values.forceSettings} onChange={handleChange} label={t('serverForceSettings')} />

                                                </FormGroup>
                                            </Row>

                                            <div className="mt-3">
                                                <Collapse title={<h5 className="m-0">{t('sharedPermissions')}</h5>}>
                                                    <div>
                                                        <FormGroup>
                                                            <CustomCheckbox name="registration" value="registration" defaultChecked={values.registration} onChange={handleChange} label={t('serverRegistration')} />

                                                        </FormGroup>
                                                        <FormGroup>
                                                            <CustomCheckbox name="readonly" value="readonly" defaultChecked={values.readonly} onChange={handleChange} label={t('serverReadonly')} />

                                                        </FormGroup>
                                                        <FormGroup>
                                                            <CustomCheckbox name="deviceReadonly" value="deviceReadonly" defaultChecked={values.deviceReadonly} onChange={handleChange} label={t('userDeviceReadonly')} />

                                                        </FormGroup>
                                                        <FormGroup>
                                                            <CustomCheckbox name="limitCommands" value="limitCommands" defaultChecked={values.limitCommands} onChange={handleChange} label={t('userLimitCommands')} />

                                                        </FormGroup>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                </Button>
                                            </div>
                                            <Button variant="secondary" onClick={() => this.props.onHide()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!this.state.changed && (!dirty || isSubmitting || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}

ServerPreferencesPage.defaultProps = {
    server: ServerInstance
}

export default ServerPreferencesPage;