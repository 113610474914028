import React from 'react';
import AttributesPage from '../Attributes';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Calendar } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import { AppService } from '../../../services';


export default class CalendarForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributesPage: false,
            item: new Calendar().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        this.props.onSave(item, values);
    }

    getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.state.item.data = reader.result.split(',')[1];
            this.setState({
                item: this.state.item,
                changed: true,
            });
        };
        reader.onerror = (error) => {
            AppService.showError(error);
        };
    }

    handleFile(e) {
        const files = Array.from(e.target.files);
        this.getBase64(files[0])
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new Calendar().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Calendar().deserialize(deepClone(newProps.item)) })
    }



    render() {
        const { sending, item } = this.state;

        const schema = yup.object({
            name: yup.string().required()
        });

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="driversAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                : null}

            <div className="container-wrapper">
               
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        file: ''
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        dirty,
                        isSubmitting,
                        errors,
                        isValid }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                        <React.Fragment><h3 className="internal-title"><i className="mdi mdi-calendar"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedCalendar')}</h3></React.Fragment>
                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedFile")}</FormLabel>
                                                <FormControl
                                                    type="file"
                                                    name="file"
                                                    onChange={this.handleFile}
                                                    isInvalid={!!errors.file}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                </Button>
                                            </div>
                                            <Button variant="secondary" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!this.state.changed && (!dirty || isSubmitting || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}