import config from "../config";
import { store } from "../store";
import devices from "../store/devices";
import positions from "../store/positions";
import * as events_store from "../store/events";
import { Device, Position, Event } from "../models";
import events from "../utils/Events";
import app from "../store/app";
import AppService from "./AppService";
import DevicesService from "./DevicesService";
import constants from "../config/constants";
import PositionsService from "./PositionsService";

export class WebSocketService {
  constructor() {
    this.socket = null;
    this.isFirstMessage = false;
    this.connected = false;
  }

  async clearState() {
    //clear positions
    store.dispatch(devices.actions.resetPositions());
    events.emit(devices.types.RESET_POSITIONS);
    //reset latest positions
    store.dispatch({ type: positions.types.RESET });
    events.emit(positions.types.RESET);
  }

  //load unknown device from event or position
  async getDevice(id) {
    try {
      let device = store.getState().devices.devices[id];
      if (!device) {
        device = await DevicesService.getOne({ id });
      }
      return store.getState().devices.devices[id];
    } catch (ex) {}
    return null;
  }

  async fireEvent(event) {
    const device = await this.getDevice(event.deviceId);
    if (device) {
      //console.log(event);
      event.title = device.name;
      //event.device = device;
      store.dispatch(events_store.actions.add(event));
      AppService.showToast(event.text, event.title, {
        type: event.displayType,
        permanent: event.permanent || false,
        onClick: () => {
          store.dispatch(devices.actions.setSelectedDevice(event.deviceId));
          events.emit(devices.types.DEVICE_SELECTED, event.deviceId);
        }
      });
    }
  }

  async connect() {
    if (!this.connected || !this.socket) {
      if (this.socket) {
        try {
          this.socket.close();
          this.socket = null;
        } catch (ex) {}
      }
      this.socket = new WebSocket(config.websocket_url);

      // Listen for messages
      this.socket.addEventListener("message", async event => {
        /*if (this.isFirstMessage) {
                    await this.clearState();

                }*/

        const data = JSON.parse(event.data);
        if (data.devices) {
          data.devices.map(d => {
            let device = store.getState().devices.devices[d.id];
            if (!device) {
              device = new Device();
            }
            device.deserialize(d);
            //device.position = store.getState().positions.positions[device.id];
            store.dispatch(devices.actions.updated(device));
            events.emit(devices.types.UPDATED, device);
            return device;
          });
        }
        if (data.positions) {
          data.positions.map(async p => {
            const newPosition = new Position().deserialize(p);
            store.dispatch(positions.actions.updated(newPosition));
            if (!this.isFirstMessage) {
              if (newPosition.hasAttribute("result")) {
                const event = new Event();
                event.type = "commandResult";
                event.serverTime = newPosition.fixTime;
                event.deviceId = newPosition.deviceId;
                event.positionId = newPosition.id;
                event.setAttribute("result", newPosition.getAttribute("event"));
                event.permanent = true;
                this.fireEvent(event);
              }
            }

            /*const position = store.getState().positions.positions[p.deviceId];
                        const device = await this.getDevice(p.deviceId);

                        if (device && position && (!currentPosition || currentPosition.id != newPosition.id)) {
                            if ((!device.position) || device.position.id != position.id) {
                                device.position = position;
                                store.dispatch(devices.actions.updated(device));
                                events.emit(devices.types.UPDATED, device);
                            }
                        }
                        return device;*/
          });
          if (this.isFirstMessage) {
            //console.log("first");
            //store.dispatch(positions.actions.loaded([]))
            events.emit(positions.types.LOADED);
          }
        }

        if (data.events) {
          data.events.map(async e => {
            const event = new Event().deserialize(e);
            this.fireEvent(event);
          });
        }

        this.isFirstMessage = false;
      });

      let promise = new Promise((resolve, reject) => {
        // Connection opened
        this.socket.addEventListener("open", event => {
          store.dispatch({
            type: app.types.CONNECTION_CHANGED,
            payload: "online"
          });
          events.emit(app.types.CONNECTION_CHANGED, "online");
          //clear all history and positions
          this.isFirstMessage = true;
          this.connected = true;
          resolve(event);
        });

        this.socket.addEventListener("close", event => {
          store.dispatch({
            type: app.types.CONNECTION_CHANGED,
            payload: "offline"
          });
          events.emit(app.types.CONNECTION_CHANGED, "offline");

          if (this.onClose) {
            this.connectToWebsocket();
          }
          //clear all history and positions
          this.isFirstMessage = true;
          this.connected = false;
        });
      });

      return promise;
    }
  }

  connectToWebsocket() {
    if (this._connectionTimeout) {
      clearTimeout(this._connectionTimeout);
    }
    if (this.user) {
      this._connectionTimeout = setTimeout(async () => {
        try {
          store.dispatch({
            type: app.types.CONNECTION_CHANGED,
            payload: "connecting"
          });
          events.emit(app.types.CONNECTION_CHANGED, "connecting");
          await DevicesService.load();
          await PositionsService.load();
          this.connect();
        } catch (ex) {
          console.log(ex);
          this.connectToWebsocket();
        }
      }, constants.reconnectTimeout);
    }
  }

  async disconnect() {
    this.socket.close();
  }
}

export default new WebSocketService();
