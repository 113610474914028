import React from 'react';
import AttributesPage from '../Attributes';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Notification } from '../../../models';
import { deepClone, isEqual } from '../../../utils/functions';
import { store } from '../../../store';
import { App } from '../../../App';
import Select from 'react-select';
import CustomSelect from '../../Controls/CustomSelect';
import CustomCheckbox from '../../Controls/CustomCheckBox';


export default class NotificationForm extends React.Component {
    constructor(props) {
        super(props);


        const notificationTypeOptions = [];
        Object.values(store.getState().notification_types.notification_types).map(n => {
            notificationTypeOptions.push({ label: t(App.App.getEventString(n.type)), value: n.type })
        });
        notificationTypeOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        const calendarOptions = [];
        Object.values(store.getState().calendars.calendars).map(o => {
            calendarOptions.push({ label: t(o.name), value: o.id })
        });
        calendarOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        const notificatorOptions = [];
        Object.values(store.getState().notificators.notificators).map(o => {
            notificatorOptions.push({ label: t('notificator' + o.name.ucFirst()), value: o.name })
        });
        notificatorOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        const alarmOptions = [];
        Object.values(store.getState().alarm_types.alarm_types).map(o => {
            alarmOptions.push({ label: o.name, value: o.key })
        });
        alarmOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)


        this.state = {
            attributesPage: false,
            item: new Notification().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false,
            notificationTypeOptions: notificationTypeOptions,
            calendarOptions: calendarOptions,
            notificatorOptions: notificatorOptions,
            alarmOptions: alarmOptions,
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.selectChanged = this.selectChanged.bind(this);
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({ attributesPage: true })
    }

    onAttributesCancel() {
        const clone = new Notification().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({ item: new Notification().deserialize(deepClone(newProps.item)) })
    }

    selectChanged(value, name, setFieldValue) {
        setFieldValue(name, value);
    }

    render() {
        const { sending, item, notificationTypeOptions, calendarOptions, notificatorOptions, alarmOptions } = this.state;

        const schema = yup.object({
            type: yup.string().required(),
            notificators: yup.string().required(),
            always: yup.boolean(),
            //calendarId: yup.number().nullable(),
        });


        return <React.Fragment>
            {
                this.state.attributesPage ?
                    <AttributesPage item={item} type="NotificationsAttributes" onHide={() => this.onAttributesClosed()} onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()} />
                    : null
            }

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        calendarId: item.calendarId,
                        type: item.type,
                        always: item.always,
                        notificators: item.notificators,
                        alarms: item.attributes ? item.attributes.alarms || '' : '',
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        dirty,
                        isSubmitting,
                        setFieldTouched,
                        errors,
                        isValid }) => {
                        return (
                            <Form onSubmit={handleSubmit} noValidate className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container">
                                            <h3 className="internal-title"><i className="mdi mdi-bell-ring"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedNotification')}</h3>
                                            <FormGroup>
                                                <FormLabel>{t("sharedType")}</FormLabel>
                                                <CustomSelect options={notificationTypeOptions} isClearable name="type" defaultValue={values.type} onChange={setFieldValue} />
                                            </FormGroup>

                                            {values.type === 'alarm' ?
                                                <FormGroup>
                                                    <FormLabel>{t("sharedAlarms")}</FormLabel>
                                                    <CustomSelect options={alarmOptions} isClearable isMulti name="attributes.alarms" defaultValue={values.alarms} onChange={setFieldValue} />

                                                </FormGroup>
                                                : null}

                                            <FormGroup>
                                                <FormLabel>{t("notificationNotificators")}</FormLabel>
                                                <CustomSelect options={notificatorOptions} isClearable isMulti name="notificators" defaultValue={values.notificators} onChange={setFieldValue} />

                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedCalendar")}</FormLabel>
                                                <CustomSelect options={calendarOptions} isClearable name="calendarId" defaultValue={values.calendarId} onChange={setFieldValue} />
                                            </FormGroup>
                                            <FormGroup>
                                                <CustomCheckbox name="always" value="always" defaultChecked={values.always} onChange={handleChange} label={t('notificationAlways')} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                <Button variant="secondary" className="align-self-stretch" onClick={() => this.openAttributesPage()}>
                                                    <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                </Button>
                                            </div>
                                            <Button variant="secondary" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="primary" disabled={!this.state.changed && (!dirty || isSubmitting || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment >
    }
}