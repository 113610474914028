import React from 'react';
import { Modal, Button, FormGroup, FormControl, FormLabel, Form } from 'react-bootstrap';
import { t } from '../../../utils/Translator';
import { store } from '../../../store';
import EditableSelect from '../../Controls/EditableSelect';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import CustomNumberField from '../../Controls/CustomNumberField';
import CustomColorField from '../../Controls/CustomColorField';


class FormModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: props.mode || 'add',
            key: props.attributeKey || '',
            type: 'text',
            value: props.attributeKey ? props.item.attributes[props.attributeKey] : '',
            store: null,
            definitions: props.definitions
        };
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    getAttributeType() {
        if (this.state.key && this.state.definitions) {
            if (this.state.definitions[this.state.key]) {
                return {
                    type: this.state.definitions[this.state.key].valueType || "text",
                    dataType: this.state.definitions[this.state.key].dataType,
                }
            }
        }
        return {
            type: "text",
            dataType: null
        }
    }

    getValueOptions(type) {
        switch (type.dataType) {
            case 'volumeUnit':
                return {
                    values: store.getState().volume_units.volume_units,
                    valueKey: 'key',
                    labelKey: 'fullName'
                }
            case 'speedUnit':
                return {
                    values: store.getState().speed_units.speed_units,
                    valueKey: 'key',
                    labelKey: 'name'
                }
            case 'distanceUnit':
                return {
                    values: store.getState().distance_units.distance_units,
                    valueKey: 'key',
                    labelKey: 'name'
                }
            case 'timezone':
                return {
                    values: Object.values(store.getState().timezones.timezones).sort((a, b) => a.key > b.key ? 1 : -1),
                    valueKey: 'key',
                    labelKey: 'key'
                }
        }
    }

    handleKeyChange(name, value) {
        this.setState({ key: value, value: this.props.item.getAttribute(value) })
    }

    handleValueChange(e) {
        if (e.target) {
            if (e.target.hasOwnProperty('checked')) {
                this.setState({ value: e.target.checked ? e.target.value : false })
            } else {
                this.setState({ value: e.target.value })
            }
        } else {
            this.setState({ value: e })
        }

    }

    handleSubmit() {
        if (this.state.key) {
            this.props.item.attributes[this.state.key] = this.state.value;
            if (undefined === this.state.value || null === this.state.value || this.state.value === '') {
                delete this.props.item.attributes[this.state.key];
            }

        }
        this.props.onHide();
    }



    getAttributeOptions() {
        const options = [];
        if (this.props.definitions) {
            Object.values(this.props.definitions).map(v => {
                options.push({ value: v.key, label: t(v['name']) })
            })
            options.sort((a, b) => a.label > b.label ? 1 : -1)
        }

        return options;
    }

    componentDidMount() {
        this.setState({ ...this.state, key: this.props.attributeKey || '' })
    }

    renderKeyField() {
        const options = this.getAttributeOptions();
        return (
            <FormGroup>
                <FormLabel>{t("sharedName")}</FormLabel>
                {options.length > 0 ?
                    <EditableSelect onChange={this.handleKeyChange} options={options} defaultValue={this.props.attributeKey} />
                    : <FormControl
                        id="attribute-key"
                        value={this.state.key}
                        placeholder=""
                        onChange={(e) => this.handleKeyChange('attribute-key', e.target.value)}
                    />}
            </FormGroup>
        )
    }

    renderValueField() {

        const type = this.getAttributeType();

        if (type.dataType) {
            const options = this.getValueOptions(type);
            if (options) {
                return <FormControl
                    as="select"
                    name="value"
                    value={this.state.value}
                    onChange={this.handleValueChange}>
                    <option value=""></option>
                    {Object.values(options.values).map(o => {
                        return <option key={o[options.valueKey]} value={o[options.valueKey]}>{t(o[options.labelKey])}</option>
                    })}
                </FormControl>
            } else {
                switch (type.type) {
                    case "number":
                        return <CustomNumberField name="value" value={this.state.value} type="number" xdatatype={type.dataType} onChange={this.handleValueChange} />;
                    case "boolean":
                        return <CustomCheckbox name="value" value={true} defaultChecked={this.state.value} onChange={this.handleValueChange} />;
                    case "color":
                        return <CustomColorField name="value" value={this.state.value} onChange={this.handleValueChange} />
                    default:
                        return <FormControl
                            type="text"
                            name="value"
                            defaultValue={this.state.value}
                            onChange={this.handleValueChange} />
                }
            }
        } else {
            switch (type.type) {
                case "number":
                    return <FormControl
                        type="number"
                        name="value"
                        defaultValue={this.state.value}
                        onChange={this.handleValueChange} />
                case "color":
                    return <CustomColorField name="value" value={this.state.value} onChange={this.handleValueChange} />
                case "boolean":
                    return <FormGroup>
                        <CustomCheckbox name="value" value={true} defaultChecked={this.state.value} onChange={this.handleValueChange} />
                    </FormGroup>
                default:
                    return <FormControl
                        type="text"
                        name="value"
                        defaultValue={this.state.value}
                        onChange={this.handleValueChange} />
            }
        }
    }


    render() {
        const { show, onHide } = this.props;

        return (
            <Modal show={show} size="md" className="custom-modal attributes-modal form" centered onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="mdi mdi-playlist-play"></i> {t((this.props.mode === 'add' ? 'sharedAdd' : 'sharedEdit'))} {t('sharedAttribute')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="visible">
                    <Form autoComplete="false">
                        {this.renderKeyField()}
                        <FormGroup>
                            <FormLabel>{t("stateValue")}</FormLabel>
                            {this.renderValueField()}
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        <i className="mdi mdi-close"></i> {t('sharedCancel')}
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmit}>
                        <i className="mdi mdi-check"></i> {t('sharedSave')}
                    </Button>
                </Modal.Footer>
            </Modal>


        )

    }
}

export default FormModal;